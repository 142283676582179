.search-box {
    background-color: #5d94db;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border-width: 1;
    border-style:solid; 
    margin-right: 0.5rem;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.5rem;
}