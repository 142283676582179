.card-container{
    display: flex;
    flex-direction: column;
    /* make it transparent */
    background-color: rgba(255, 255, 255, 0.1);

    border: 1px solid gray;
    border-radius: 10px;
    
    padding: 8px;
    cursor: pointer;
    transition: transform 0.25s ease-out;

}
.card-container:hover{
    transform: scale(1.05);
}

.lottery-number {
    color: white;
}