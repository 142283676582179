@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.title-style {
    color: black;
    margin-top: 5vh;
}

.inner-text {
    font-size: 2.5em;
    font-weight: bold;
    font-family: 'Roboto';
    color: white;
}