@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}
h1{
  font-size: 50px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.search-box {
    background-color: #5d94db;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    border-width: 1;
    border-style:solid; 
    margin-right: 0.5rem;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 1.5rem;
}
.card-container{
    display: flex;
    flex-direction: column;
    /* make it transparent */
    background-color: rgba(255, 255, 255, 0.1);

    border: 1px solid gray;
    border-radius: 10px;
    
    padding: 8px;
    cursor: pointer;
    transition: transform 0.25s ease-out;

}
.card-container:hover{
    transform: scale(1.05);
}

.lottery-number {
    color: white;
}
.card-list {
  width: 80vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 5px;
}

.number-of-lottery {
  font-weight: bolder;
}

.lottery-num {
  font-size: 30px;
  font-family: 'Nunito Sans';
  font-weight: bolder;
  color: white !important;
}

.title-style {
    color: black;
    margin-top: 5vh;
}

.inner-text {
    font-size: 2.5em;
    font-weight: bold;
    font-family: 'Roboto';
    color: white;
}
